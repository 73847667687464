import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public _headers: any;

  constructor(public http: HttpClient) {
    this._headers = {
      'Content-Type': 'application/json'
    };
  }

  /*********************************   Common   ***********************************/

  uploadFile(filepath: any) {
    return this.http.post<any>(`${environment.baseURL}/file/upload`, filepath)
      .pipe(map(data => {
        return data;
      }));
  }

  /*********************************   Auth   ***********************************/

  // Send OTP
  sendOTP(data: any) {


    return this.http.post<any>(`${environment.baseURL}/auth/sendOTP`, data, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((data: any) => {
        return data;
      }));
  }

  // Resend OTP
  resendOTP(data: any) {


    return this.http.post<any>(`${environment.baseURL}/auth/resendOTP`, data, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((data: any) => {
        return data;
      }));
  }


  // Authenticate OTP
  authenticateUser(data: any) {

    return this.http.post<any>(`${environment.baseURL}/auth/authenticate`, data, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((data: any) => {
        return data;
      }));
  }

  // Register User
  registerUser(data: any) {
    return this.http.post<any>(`${environment.baseURL}/auth/register`, data, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((data: any) => {
        return data;
      }));
  }

  // Read User
  getUser(condition: any, page: number = 1, limit: number = 25, searchTerm: any) {
    condition = JSON.stringify({
      condition: condition
    });
    return this.http.post<any>(`${environment.baseURL}/user/get?page=` + page + '&limit=' + limit + '&searchTerm=' + searchTerm, condition, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((data: any) => {
        return data;
      }));
  }

  // Create User
  createUser(data: any) {

    return this.http.post<any>(`${environment.baseURL}/user`, data, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((data: any) => {
        return data;
      }));
  }

  // Create User
  updateUser(data: any, id: any) {

    return this.http.put<any>(`${environment.baseURL}/user/` + id, data, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((data: any) => {
        return data;
      }));
  }

  // Services Expert Count
  getVendorServicesCount(serviceId: any) {

    return this.http.get<any>(`${environment.baseURL}/user/services-vendor-count/` + serviceId)
      .pipe(map((data: any) => {
        return data;
      }));
  }



  // Download FSI
  downloadFSI(id: any) {

    return this.http.get<any>(`${environment.baseURL}/file/download-fsi/` + id)
      .pipe(map((data: any) => {
        return data;
      }));
  }

  // Download SideMargin
  downloadSideMargin(id: any) {

    return this.http.get<any>(`${environment.baseURL}/file/download-side/` + id)
      .pipe(map((data: any) => {
        return data;
      }));
  }

  // Download Parking
  downloadParking(id: any) {

    return this.http.get<any>(`${environment.baseURL}/file/download-parking/` + id)
      .pipe(map((data: any) => {
        return data;
      }));
  }

  /*********************************   Chats   ***********************************/

  // Create Conversation
  createConversation(data: any) {

    return this.http.post<any>(`${environment.baseURL}/conversation`, data, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((data: any) => {
        return data;
      }));
  }

  // Update Conversation
  updateConversation(data: any, id: any) {

    return this.http.put<any>(`${environment.baseURL}/conversation/` + id, data, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((data: any) => {
        return data;
      }));
  }

  // Read Conversation
  getConversation(condition: any, page: number = 1, limit: number = 25, searchTerm: any) {
    condition = JSON.stringify({
      condition: condition
    });
    return this.http.post<any>(`${environment.baseURL}/conversation/get?page=` + page + '&limit=' + limit + '&searchTerm=' + searchTerm, condition, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((data: any) => {
        return data;
      }));
  }



  // Get Messages
  getMessages(condition: any, page: number = 1, limit: number = 25, searchTerm: any) {
    condition = JSON.stringify({ condition: condition });
    return this.http.post<any>(`${environment.baseURL}/messages/get?page=` + page + '&limit=' + limit + '&searchTerm=' + searchTerm, condition, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((data: any) => {
        return data;
      }));
  }

  /*********************************   Contact Us   ***********************************/

  // Create Contact Us
  createContactUs(data: any) {

    return this.http.post<any>(`${environment.baseURL}/contactus`, data, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((data: any) => {
        return data;
      }));
  }

  /*********************************   New Service Add   ***********************************/

  // Create  New Service Add
  createNewServiceAdd(data: any) {

    return this.http.post<any>(`${environment.baseURL}/newServiceAdd`, data, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((data: any) => {
        return data;
      }));
  }


  /*********************************   New Calculator Complain   ***********************************/

  // Create  New Calculator Complain
  createCalculatorComplain(data: any) {

    return this.http.post<any>(`${environment.baseURL}/calculatorComplain`, data, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((data: any) => {
        return data;
      }));
  }


  /*********************************   Questions   ***********************************/

  // Create Question
  createQuestion(data: any) {

    return this.http.post<any>(`${environment.baseURL}/question`, data, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((data: any) => {
        return data;
      }));
  }

  // Read Question
  getQuestion(condition: any, page: number = 1, limit: number = 25, searchTerm: any, sort: any = { createdAt: -1 }) {
    condition = JSON.stringify({ condition: condition });
    sort = JSON.stringify(sort)
    return this.http.post<any>(`${environment.baseURL}/question/get?page=` + page + '&limit=' + limit + '&searchTerm=' + searchTerm + '&sort=' + sort, condition, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((data: any) => {
        return data;
      }));
  }

  // Update Question
  updateQuestion(data: any, id: any) {

    return this.http.put<any>(`${environment.baseURL}/question/` + id, data, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((data: any) => {
        return data;
      }));
  }

  // Update View Count Question
  updateViewCountQuestion(id: any) {

    return this.http.get<any>(`${environment.baseURL}/question/count/` + id)
      .pipe(map((data: any) => {
        return data;
      }));
  }

  /*********************************   Projects   ***********************************/

  // Create Project
  createProject(data: any) {
    return this.http.post<any>(`${environment.baseURL}/project`, data, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((data: any) => {
        return data;
      }));
  }

  // Read Project
  getProject(condition: any, page: number = 1, limit: number = 25, searchTerm: any) {
    condition = JSON.stringify({ condition: condition });
    return this.http.post<any>(`${environment.baseURL}/project/get?page=` + page + '&limit=' + limit + '&searchTerm=' + searchTerm, condition, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((data: any) => {
        return data;
      }));
  }

  // Update Project
  updateProject(data: any, id: any) {

    return this.http.put<any>(`${environment.baseURL}/project/` + id, data, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((data: any) => {
        return data;
      }));
  }

  /*********************************   Proposals   ***********************************/

  // Create Proposal
  createProposal(data: any) {

    return this.http.post<any>(`${environment.baseURL}/proposal`, data, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((data: any) => {
        return data;
      }));
  }

  // Read Proposal
  getProposal(condition: any, page: number = 1, limit: number = 25, searchTerm: any) {
    condition = JSON.stringify({ condition: condition });
    return this.http.post<any>(`${environment.baseURL}/proposal/get?page=` + page + '&limit=' + limit + '&searchTerm=' + searchTerm, condition, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((data: any) => {
        return data;
      }));
  }

  // Update Proposal
  updateProposal(data: any, id: any) {

    return this.http.put<any>(`${environment.baseURL}/proposal/` + id, data, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((data: any) => {
        return data;
      }));
  }


  /*********************************   Fsis   ***********************************/

  // Create Fsi
  createFsi(data: any) {

    return this.http.post<any>(`${environment.baseURL}/fsi`, data, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((data: any) => {
        return data;
      }));
  }

  // Read Fsi
  getFsi(condition: any, page: number = 1, limit: number = 25, searchTerm: any) {
    condition = JSON.stringify({ condition: condition });
    return this.http.post<any>(`${environment.baseURL}/fsi/get?page=` + page + '&limit=' + limit + '&searchTerm=' + searchTerm, condition, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((data: any) => {
        return data;
      }));
  }

  // Update Fsi
  updateFsi(data: any, id: any) {

    return this.http.put<any>(`${environment.baseURL}/fsi/` + id, data, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((data: any) => {
        return data;
      }));
  }

  // Delete Fsi
  deleteFsi(id: any) {

    return this.http.delete<any>(`${environment.baseURL}/fsi/` + id,)
      .pipe(map((data: any) => {
        return data;
      }));
  }

  // Read Service
  getService(condition: any, page: number = 1, limit: number = 25, searchTerm: any) {
    condition = JSON.stringify(condition);
    return this.http.post<any>(`${environment.baseURL}/service/get?page=` + page + '&limit=' + limit + '&searchTerm=' + searchTerm, condition, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((data: any) => {
        return data;
      }));
  }


  /*********************************   SideMargins   ***********************************/

  // Create SideMargin
  createSideMargin(data: any) {

    return this.http.post<any>(`${environment.baseURL}/sideMargin`, data, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((data: any) => {
        return data;
      }));
  }

  // Read SideMargin
  getSideMargin(condition: any, page: number = 1, limit: number = 25, searchTerm: any) {
    condition = JSON.stringify({ condition: condition });
    return this.http.post<any>(`${environment.baseURL}/sideMargin/get?page=` + page + '&limit=' + limit + '&searchTerm=' + searchTerm, condition, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((data: any) => {
        return data;
      }));
  }

  // Update SideMargin
  updateSideMargin(data: any, id: any) {

    return this.http.put<any>(`${environment.baseURL}/sideMargin/` + id, data, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((data: any) => {
        return data;
      }));
  }

  // Delete SideMargin
  DeleteSideMargin(id: any) {

    return this.http.delete<any>(`${environment.baseURL}/sideMargin/` + id)
      .pipe(map((data: any) => {
        return data;
      }));
  }

  /*********************************   Parkings   ***********************************/

  // Create Parking
  createParking(data: any) {

    return this.http.post<any>(`${environment.baseURL}/parking`, data, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((data: any) => {
        return data;
      }));
  }

  // Read Parking
  getParking(condition: any, page: number = 1, limit: number = 25, searchTerm: any) {
    condition = JSON.stringify({ condition: condition });
    return this.http.post<any>(`${environment.baseURL}/parking/get?page=` + page + '&limit=' + limit + '&searchTerm=' + searchTerm, condition, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((data: any) => {
        return data;
      }));
  }

  // Update Parking
  updateParking(data: any, id: any) {

    return this.http.put<any>(`${environment.baseURL}/parking/` + id, data, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((data: any) => {
        return data;
      }));
  }

  // Delete Parking
  deleteParking(id: any) {

    return this.http.delete<any>(`${environment.baseURL}/parking/` + id)
      .pipe(map((data: any) => {
        return data;
      }));
  }

  /*********************************   Service Request   ***********************************/

  // Create Question
  createServiceRequest(data: any) {

    return this.http.post<any>(`${environment.baseURL}/serviceRequest`, data, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((data: any) => {
        return data;
      }));
  }

  // Read Question
  getServiceRequest(condition: any, page: number = 1, limit: number = 25, searchTerm: any) {
    condition = JSON.stringify({ condition: condition });
    return this.http.post<any>(`${environment.baseURL}/serviceRequest/get?page=` + page + '&limit=' + limit + '&searchTerm=' + searchTerm, condition, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((data: any) => {
        return data;
      }));
  }

  // Update Question
  updateServiceRequest(data: any, id: any) {

    return this.http.put<any>(`${environment.baseURL}/serviceRequest/` + id, data, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((data: any) => {
        return data;
      }));
  }


  /*********************************   Blog   ***********************************/

  // Create Blog
  createBlog(data: any) {

    return this.http.post<any>(`${environment.baseURL}/blog`, data, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((data: any) => {
        return data;
      }));
  }

  // Read Blog Tags
  getBlogTags() {
    return this.http.get<any>(`${environment.baseURL}/blog/getTags`)
      .pipe(map((data: any) => {
        return data;
      }));
  }

  // Read Blog
  getBlog(condition: any, page: number = 1, limit: number = 25, searchTerm: any) {
    condition = JSON.stringify({ condition: condition });
    return this.http.post<any>(`${environment.baseURL}/blog/get?page=` + page + '&limit=' + limit + '&searchTerm=' + searchTerm, condition, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((data: any) => {
        return data;
      }));
  }

  // Update Blog
  updateBlog(data: any, id: any) {

    return this.http.put<any>(`${environment.baseURL}/blog/` + id, data, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((data: any) => {
        return data;
      }));
  }



  // Create Report
  createReport(data: any) {
    return this.http.post<any>(`${environment.baseURL}/report`, data, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((data: any) => {
        return data;
      }));
  }

  // Read Report
  getCreate(condition: any, page: number = 1, limit: number = 25, searchTerm: any, sort: any = { createdAt: -1 }) {
    condition = JSON.stringify({ condition: condition });
    return this.http.post<any>(`${environment.baseURL}/report/get?page=` + page + '&limit=' + limit + '&searchTerm=' + searchTerm + '&sort=' + sort, condition, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((data: any) => {
        return data;
      }));
  }


  // create rating
  createRating(data: any) {
    return this.http.post<any>(`${environment.baseURL}/rating`, data, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((data: any) => {
        return data;
      }));
  }

  // get rating
  getRating(condition: any, page: number = 1, limit: number = 25, searchTerm: any, sort: any = { createdAt: -1 }) {
    condition = JSON.stringify(condition);
    return this.http.post<any>(`${environment.baseURL}/rating/get?page=` + page + '&limit=' + limit + '&searchTerm=' + searchTerm + '&sort=' + sort, condition, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((data: any) => {
        return data;
      }));
  }


  // Read Notification
  getNotification(condition: any, page: number = 1, limit: number = 25, searchTerm: any, sort: any = { createdAt: -1 }) {
    condition = JSON.stringify(condition);
    sort = JSON.stringify(sort)
    return this.http.post<any>(`${environment.baseURL}/notification/get?page=` + page + '&limit=' + limit + '&searchTerm=' + searchTerm + '&sort=' + sort, condition, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((data: any) => {
        return data;
      }));
  }

  // Read Notification
  deleteNotificationAll(condition: any) {
    condition = JSON.stringify(condition);
    return this.http.post<any>(`${environment.baseURL}/notification/deleteall`, condition, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((data: any) => {
        return data;
      }));
  }

  // Update Notification
  updateNotificationAll(condition: any, filter: any) {
    condition = JSON.stringify({ data: condition, filter: filter });
    return this.http.post<any>(`${environment.baseURL}/notification/updateall`, condition, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((data: any) => {
        return data;
      }));
  }

}
